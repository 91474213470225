.App {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-content: center; */
}

.hidden {
  display: none;
}
/* banner -------------------------------------- */
.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  margin-top: 110px;
  width: 100%;
  background-color: red; /* For browsers that do not support gradients */
  background-image: linear-gradient(
    rgb(104, 38, 38),
    rgb(0, 0, 0)
  ); /* Standard syntax (must be last) */
  /* background-image: url("../images/background.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover; */
  margin-bottom: -50px;
}
.mobilePageBanner {
  height: 40vh;
}
.bannerTopText {
  height: 130px;
  margin: 0 auto;
  font-size: 70px;
  color: white;
}
.bannerBottomText {
  height: 20px;
  margin: 0 auto;
  /* width: 80px; */
  padding-bottom: 30px;
  color: white;
  border-bottom: 1px solid white;
}
.bannerTitle {
  font-size: x-large;
  margin: 0 auto;
  /* width: 80px; */
  padding-bottom: 30px;
  color: white;
}

@media only screen and (max-width: 800px) {
  .banner {
    margin-top: 80px;
    margin-bottom: 0px;
  }
  .mobilePageBanner {
    height: 50vh;
    margin-bottom: -100px;
  }
}

@media only screen and (max-width: 670px) {
  /* .banner {
    height: 50vh;
    
  } */
  .mobilePageBanner {
    display: none;
  }
  .bannerTopText {
    height: 80px;
    font-size: 9vw;
    color: white;
  }
  .bannerTitle {
    display: none;
  }
}

/* products ----------------------------------- */

.cardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 300px;
  width: 95%;
  height: 100%;
  margin: auto;
  margin-bottom: 100px;
}
.card {
  display: flex;
  flex-direction: column;
  align-content: center;
  min-width: 300px;
  max-width: 400px;
  width: 25%;
  background: white;
  border: 1px solid #eeeeee;
  padding: 40px;
  overflow: hidden;
  height: 400px;
  text-decoration: none;
  color: #222222;
}
.card:hover .hover-message {
  visibility: visible;
  transition: 0.5s linear;
  opacity: 1;
}

.imgcontainer {
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  overflow: hidden;
  color: #ffffff;
}

.hover-message {
  visibility: hidden;
  transition: 0.2s linear;
  opacity: 0.3;
  transition: 0.1s;
  /* transform: translateX(-50%) translateY(-50%); */
  /* margin: auto; */
  background-color: #00b7ef;
  min-width: 180px;
  text-transform: uppercase;
  padding: 24px 48px;
  position: absolute;
  text-align: center;
}
.cardImg {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .cardContainer {
    width: 100%;
  }
  .card {
    min-width: 40px;
    max-width: 500px;
    width: 35%;
    height: 300px;
  }
}

@media only screen and (max-width: 670px) {
  .cardContainer {
    margin-bottom: 0px;
  }
  .card {
    max-width: 100%;
    width: 100%;
    height: auto;
    min-height: 550px;
    justify-content: center;
  }
}

/* instagram -------------------------- */
.instagramContainer {
  min-height: 200px;
  margin: 0 auto 100px auto;
  width: 70%;
  background: #eeeeee;
}

@media only screen and (max-width: 800px) {
  .instagramContainer {
    min-height: 200px;
    margin: 80px auto 100px auto;
    width: 100%;
  }
}

@media only screen and (max-width: 670px) {
}

/* cart------------------------ */

.cartContainer {
  margin: 0 auto 100px auto;
  width: 50%;
  min-height: 150px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cartContent {
  width: 100%;
}
.emptyCartText {
  font-size: 1rem;
}
.emptyCartText a {
  text-decoration: none;
  color: red;
}
.cartCard {
  width: 100%;
  height: 100px;
  overflow: hidden;
  color: black;
  display: flex;
  border: 1px solid #eeeeee;
}
.cartCardImageContainer {
  width: 100px;
  min-width: 100px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-right: 1px solid #eeeeee;
}
.cartCardImageContainer button {
  border: none;
  display: flex;
  margin: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  justify-content: center;
  background: white;
  border-radius: 100%;
  color: black;
  cursor: pointer;
}

.cartCardDescription {
  /* width: 70%; */
  text-align: left;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  flex-grow: 2;
}

.cartCardCurrency {
  margin: 0;
  color: red;
}
.cartCardName {
  margin: 0;
}
.cartCartOption {
  margin: 0;
  opacity: 0.5;
}
.cartTotalCheckout {
  width: 100%;
}
.cartSubtotal {
  height: 75px;
  background: black;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartOptions {
  height: 50px;
  /* background: black; */
  display: flex;
  width: 100%;
}
.cartOptions button {
  width: 50%;
  color: white;
  border: none;
  opacity: 1;
}

.cartOptions button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.cartOptions button:first-child {
  background-color: #aaaaaa;
}
.cartOptions button:last-child {
  background: #00b7ef;
}

.cartCardQuantityContainer {
  float: right;
  margin-right: 20px;
}

.quantityContainer {
  position: relative;
  top: 25%;
  border: 2px solid #eeeeee;
  float: right;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 48px; */
  padding: 5px 5px;
  /* padding-right: 10px; */
  width: 75px;
}

.quantityContainer span {
  display: flex;
  align-items: center;
  width: 48%;
  opacity: 0.6;
  font-size: small;
}

.quantityContainer input {
  text-align: right;
  width: 48%;
  outline: none;
  border: none;
  color: #222222;
}

@media only screen and (max-width: 800px) {
  .cartContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 670px) {
  .cartContainer {
    justify-content: flex-start;
    margin: 0 auto 0 auto;
    padding-top: 80px;
    padding-bottom: 125px;
    width: 100%;
    min-height: 150px;
  }
  .cartCard {
    width: 100%;
    height: 200px;
  }
  .cartCardImageContainer {
    /* width: 200px; */
    min-width: 35%;
    background-size: 130%;
    background-position: 50%;
    border-right: 2px solid #eeeeee;
  }
  .cartCardDescription {
    padding: 0px 10px;
  }
  .cartCardQuantityContainer {
    float: right;
    margin-right: 0px;
    border-left: 2px solid #eeeeee;
    text-align: center;
  }
  .quantityContainer {
    border: none;
    flex-direction: column;
  }
  .quantityContainer span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: large;
  }
  .quantityContainer input {
    margin-top: 10px;
    text-align: center;
    width: 100%;
    font-size: large;
  }
  .cartSubtotal {
    height: 75px;
    background: black;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cartOptions {
    height: 50px;
    /* background: black; */
    display: flex;
    width: 100%;
  }
  .cartTotalCheckout {
    position: fixed;
    bottom: 0px;
  }
}

/* contact form ---------------- */

.contactAlertMessage {
  background: rgb(234, 72, 89);
  margin: 0;
  min-height: 80px;
  display: flex;
  justify-content: center;
}
.contactAlertMessage h5 {
  color: white;
  margin: auto;
}
.contactForm {
  width: 100%;
}
.contactformInputLine {
  /* width: 100%; */
  padding: 0 50px;
  margin: auto;
  border-bottom: 5px solid #eeeeee;
  display: flex;
  min-height: 100px;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}
.contactformInputLine input {
  width: 70%;
  height: 40px;
  border: 2px solid #222222;
  opacity: 0.6;
  font-size: 1.3rem;
}
.contactformInputLine textarea {
  /* padding:10px 0 ; */
  border: 2px solid #222222;
  opacity: 0.6;
  margin: 20px 0;
  width: 70%;
  height: 70px;
  resize: vertical;
  font-size: x-large;
}

.formButton {
  background: #00b7ef;
  width: 100%;
  height: 50px;
  color: white;
  border: none;
  opacity: 1;
  font-size: 20px;
  /* margin: 20px 0; */
}

.formButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 670px) {
}
