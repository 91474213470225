.authContainer {
  /* background-image: url("../images/login_background.jpg"); */
  background-color: red; /* For browsers that do not support gradients */
  background-image: linear-gradient(rgb(196, 66, 66), rgb(121, 121, 115)); /* Standard syntax (must be last) */
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  /* margin-top: 110px; */
  /* background-position: -10% 100%; */
  /* background-repeat: no-repeat; */
}

.formContainer {
  padding-top: 100px;
  width: 300px;
  margin: auto;
}
.authLogo {
  width: 220px;
  /* border-radius: 100px; */
  margin:20px;
}
.formError {
    background:red;
    font-size: 1rem;
}
.form {
  margin: auto;
  background-color: white;
  /* padding:20px; */
  width: 250px;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 3px;
}

.authFormInput label {
  padding: 0 10px;
  border: none;
  font-size: 1rem;
  /* height:100px; */
}

.formBottomText {
  font-size: 1rem;
  color: black;
}
.formBottomText a {
    color: black;
}
