.productContainer {
    margin: -150px auto 100px auto;
    width: 95%;
    min-height: 200px;
    height: 100%;
    max-height: 80vh;
    background: white;
    display: flex;
    justify-content: center;
    /* align-self: flex-start; */
    flex-direction: row;
    overflow: hidden;
  }
  
  .productImageContainer {
    width: 60%;
    /* min-height:600px; */
    height: 100%;
    max-height: 80%;
    align-self: center;
    border-right: 2px solid #eeeeee;
    /* height:100%; */
    /* border: 1px solid #eeeeee; */
  }
  .productImageContainer img {
    width: 80%;
    padding: 30px;
    max-height: 50%;
  }
  .productInfoContainer {
    width: 30%;
    margin: auto;
    height: 60vh;
    /* max-height: 100vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
  
  .product-price-title {
    /* padding:10px 5% 0 5% ; */
    /* margin: 0 auto; */
    width: 100%;
    font-size: 1.3rem;
    text-align: left;
  }
  
  .product-price {
    color: red;
    margin: 0;
  }
  
  .product-name {
    margin: 0;
  }
  
  .addToCartButton {
    background: #00b7ef;
    /* width: 80%; */
    height: 70px;
    color: white;
    border: none;
    opacity: 1;
    font-size: 20px;
    margin: 20px 0;
  }
  
  .addToCartButton:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .descriptionContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
    /* height:100%; */
  }
  
  .descriptionParagraph {
    margin: 6px 0;
    font-size: 1rem;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(179, 179, 179);
  }
  .paragraphLine {
    margin: 0;
  }
  
  @media only screen and (max-width: 800px) {
    .productContainer {
      margin: -250px auto 100px auto;
    }
    .productInfoContainer {
      margin-top: 30px;
    }
  }
  
  @media only screen and (max-width: 670px) {
    .productContainer {
      flex-direction: column;
    }
    .productContainer {
      margin: 100px auto 100px auto;
      padding-bottom:30px;
      max-height: 200%;
    }
    .productInfoContainer {
      margin-top: 30px;
      width: 100%;
      height: auto
    }
    .productImageContainer {
      width: 100%;
      max-height: 80%;
      border: none;
      
    }
  }