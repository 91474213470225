footer {
  background: #000000;
  width: 100%;
  /* padding:20px; */
  min-height: 200px;
  padding: 50px 0;
  display: flex;
  justify-content: center;
}

.footerTopicContainer {
  display: flex;
  width: 70%;
  /* margin: auto; */

  /* margin: 0 auto; */
}

.footerLinksContainer {
  text-align: left;
  padding: 0 32px 0 0;
  width: 25%;
  max-width: 250px;
  display: flex;
  color: #5a5a5a;
  flex-direction: column;
}

footer p {
  font-size: 14px;
  color: white;
  font-family: sans-serif;
  margin-top: 0;
}
footer a {
  font-size: 12px;
  padding: 5px 0;
  color: #5a5a5a;
  text-decoration: none;
  display: flex;
  align-items: center;
}
footer a :hover {
  color: white;
}

.anchorSpace {
  margin-left: 5px;
}

.footerFeature {
  /* width:200px; */
}
.footerFeature a {
  background: rgb(255, 255, 255);
  padding: 5px;
  font-weight: bold;
  border-radius: 5px;
}

/* .footerFeature a :hover {
  background:rgb(243, 238, 238)
  } */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 670px) {
  footer {
    display: none;
  }
}
